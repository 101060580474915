import React, { useContext }  from 'react';

import { Row, Col, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

const FailedMessages = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {}
  config["failed_messages"] = {
        "collection": "messages",
        "defaultSort": "kevin",
        "name": "Messages",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "None",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "FAQ ID",
            name: "id",
            input_type: "display",
            default: "",
            required: false
          },
          {
            placeholder: "",
            display_name: "Request",
            name: "request",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Response",
            name: "response",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Date",
            name: "creationDate",
            input_type: "text",
            default: "",
            required: true
          },
        ],
        "table": [
          { selector: (row) => row.user_id, name: "User ID", sortable: true, minWidth: "10%" },
          { selector: (row) => row.agent_id, name: "Agent ID", sortable: true, minWidth: "10%" },
          { selector: (row) => row.request, name: "Request", sortable: true, minWidth: "10%" },
          { selector: (row) => row.response, name: "Response", sortable: true, minWidth: "50%" }
        ],
        "filterField": "response",
        "exportFields": ["request", "response"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
  return (
                <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                  <Grid item xs={12}>
                    <Paper style={superAppStyle.cardStyle} elevation={8}>
                      <SuperAppObject depth="1" style={superAppStyle} title="Failed Messages" config={config["failed_messages"]} user={props.user} userDeep={props.userDeep} collection_name="XXXX" input_data={props.failedMessages} display_state="BASIC_TABLE" canEdit="false" />
                    </Paper>
                  </Grid>
                </Grid>
  );
};

export default FailedMessages;
