// Sidebar.js
import React, { useState } from 'react';

import RowMargin from "./RowMargin";

const Sidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState("Config");
  const [style, setStyle] = useState({
    border: "none",
    backgroundColor: "transparent" });

  const activeStyle = {
    border: "none",
    backgroundColor: "transparent",
    color: "#32a111"
  }

/*
              <div to={"home"} className={activeMenu === 'home' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('home') }} >Home</div>
*/
  return (
    <div>
      <RowMargin />
      <RowMargin />
      <ul>
        <li><button style={activeMenu === 'Config' ? activeStyle:style} onClick={(e) => { setActiveMenu("Config"); props.callback("Config");}}>Admin Config</button></li>
        <li><button style={activeMenu === 'Statistics' ? activeStyle:style} onClick={(e) => { setActiveMenu("Statistics"); props.callback("Statistics")}}>Statistics</button></li>
        <li><button style={activeMenu === 'Plans' ? activeStyle:style} onClick={(e) => { setActiveMenu("Plans"); props.callback("Plans")}}>Plans</button></li>
        <li><button style={activeMenu === 'Failed Messages' ? activeStyle:style} onClick={(e) => { setActiveMenu("Failed Messages"); props.callback("Failed Messages")}}>Failed Messages</button></li>
        <li><button style={activeMenu === 'Feedback' ? activeStyle:style} onClick={(e) => { setActiveMenu("Feedback"); props.callback("Feedback")}}>Feedback</button></li>
        <li><button style={activeMenu === 'Users' ? activeStyle:style} onClick={(e) => { setActiveMenu("Users"); props.callback("Users")}}>Users</button></li>
        <li><button style={activeMenu === 'AdminAgents' ? activeStyle:style} onClick={(e) => { setActiveMenu("AdminAgents"); props.callback("AdminAgents")}}>Agents</button></li>
      </ul>
    </div>
  );
};

export default Sidebar;
