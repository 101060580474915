import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import GlobalContext from '../../GlobalContext';
import { useMyContext } from '../../MyContext';
import { Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';
/* NEW MENU STUFF */
import MenuItem from "@mui/material/MenuItem";
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
/* NEW MENU STUFF */
import Editor from 'react-simple-wysiwyg';


//Components
import TopMargin from "../TopMargin";
import RowMargin from "../RowMargin";
import List from "../List";
import Loading from "../Loading";
import PopoverPicker from "../PopoverPicker/PopoverPicker";
import UploadFile from "../UploadFile";
import { evaluateDynamicLogic } from "./utils";
import "../../pages/Home.css"
import { v4 as uuid } from 'uuid';


// generate select dropdown option list dynamically
function Options({ options, def }) {
  if (options && options.length > 0 && typeof options[0] === 'object') {
     return (
       options.map(option => 
         <option key={option["id"]} value={option["id"]}>
           {option["agentName"]} - {option["agentType"]}
         </option>)
     );
  } else { /* must be a string ....might want to be explicit here */
     
     return (
       options.map(option => {
         //const attributes = {}
         //console.log(option + "," + def);
         //if (option === def) attributes["selected"] = "true";
         return (<option key={option} >
           {option}
         </option>)
         })
     );
  }
}


export default function CreateEdit(props) {

  const globalData = useContext(GlobalContext);
  const globalContextData = useMyContext();
  const [validated, setValidated] = useState(false); /* for Bootstrap form validation */
  const [validationString, setValidationString] = useState(""); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */
  const [originalData, setOriginalData] = useState(props.originalData);
  const [actionType, setActionType] = useState(props.actionType);
  const [formData, setFormData] = useState({
    data: props.this_object,
    status: "IDLE"
  });
  const debug = globalContextData["data"]["debugOn"];

/* NEW MENU STUFF */

function DrawMenu(props)  {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuSelect = (e, value, field) => {
        e.preventDefault();

        setFormData({
          data: { ...formData.data, [field]: value },
          status: "IDLE"
        });

        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        if (anchorEl === null)
           setAnchorEl(event.currentTarget);
        else
           setAnchorEl(null);
    };

return (
<>
&nbsp;
            <button
                type="button"
                style={{"border":"none", "backgroundColor": "transparent"}}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}>
                (Show/Hide Samples)
            </button>
&nbsp;
            <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleMenuClick}
                open={Boolean(anchorEl)}
            >
            { props.field.samples.map(sample => {
                return(
                   <MenuItem key={sample} onClick={(e) => {handleMenuSelect(e, sample, props.field.name)}}>
                      {sample.startsWith("https://") &&
                      <img src={sample}></img>
                      }
                      {!sample.startsWith("https://") &&
                      sample
                      }
                   </MenuItem>
                )
             })
            }
            </Menu>
</>
)
}
/* END NEW MENU STUFF */

  const isInvalidColor = (strColor) => {
      if (typeof strColor === 'undefined') return true;
      const s = new Option().style;
      s.color = strColor;
      if (debug) console.log("checking color for " + strColor + "," + (s.color === ''));
      return s.color === '';
   }

  const isInvalidField = (str, field) => {
      field.error_message = ""; 
if (debug) console.log("in validField - " + JSON.stringify(field));
      if (typeof field.max_length === 'string') {
          const values = field.max_length.split(".");
          const tryNext = false;
          if (values[0] === 'users') {
             if (values[1] in props.userDeep) {
if (debug) console.log("in userdeep")
                field.max_length = props.userDeep[values[1]]; 
             }
             else
                values[0] = "adminConfig"; // check for a value higher up the hierarchy..did this because variable might not be set for 1000 users when a new config is set but putting at adminConfig or ENVIRONMENT is relatively easy as a catch all 
          }
          if (values[0] === 'adminConfig') {
             if (values[1] in globalContextData["data"]) {
                field.max_length = globalContextData["data"][values[1]];
if (debug) console.log("in adminConfig")
             }
             else
                values[0] = "env"; // check for a value higher up the hierarchy..did this because variable might not be set for 1000 users when a new config is set but putting at adminConfig or ENVIRONMENT is relatively easy as a catch all 
          }
          if (values[0] === 'env') {
             if (values[1] in process.env[values[1]])
                field.max_length = process.env[values[1]]; 
          }
      }

      if (Number.isInteger(field.max_length)) {
          field.max_length = Number(field.max_length); 
      }

      if (field.max_length && str && str.length > field.max_length) { 
         field.error_message = "Field too long"; 
         return true; 
      }

      if (!Number.isInteger(str)) {
          str = Number(str); 
      }
      if (field.validate_number && !Number.isInteger(str)) {
         field.error_message = "Field should be a valid integer"; 
         return true; 
      }

      return false;
   }

   /* Have to keep this logic consistent with a copy in CreateEdit, TODO: move to a shared library  */
   function canDeleteRow(props, config, rowData) {

      if ("locked" in rowData && rowData["locked"] === true) return false;

      if (props.userDeep && props.userDeep.roles && props.userDeep.roles.toLowerCase().indexOf("delete") >= 0) return true;

      if (config.security && config.security.delete && config.security.delete.indexOf("AllUsers") >= 0)
         return true;

      if (config.security && config.security.delete && config.security.delete.indexOf("createdBy") >= 0 && props.user && rowData.user && props.user === rowData.createdBy)
         return true;

      return false;
   }


  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    var colCounter = 0;
    var colArray = [];
    var rowArray = [];
    for (let x = 0; x < arr.length; x++) {
      var element = arr[x];
      if (element.input_type === "none") {
        x++;
        element = arr[x];
      }

      var colspan = 1;
      if ("colspan" in element) {
        colspan = element["colspan"];
        if (colspan === 3) colCounter = n - 1;
        if (colspan + colCounter >= n) {
          colCounter = n - 1;
        }
      }
      colArray.push(element);
      colCounter++;
      if (colCounter === n) {
        rowArray.push(colArray);
        colArray = [];
        colCounter = 0;
      }
    }
/* KAD added this Sept 16, 2024 */
    if (colCounter != 0) {
        rowArray.push(colArray);
    }
    return rowArray;
  };
  const onColorChange = (value, name) => {
    if (debug) console.log("onColorChange " + name + "," + value);
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  }
  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };
  const onHTMLChange = (e, name)  => {
    setFormData({
      data: { ...formData.data, [name]: e.target.value },
      status: "IDLE"
    });
  }
  const formInvalid = () => {
    var invalid = false;
    var fieldString = "";
    props.config.fields.map(field => {
      var showField = true;
      if (typeof field.conditional !== 'undefined') {
        //     conditional: "AGENT_TYPE === DIALOGFLOW",
/* Need to finish this code to handle more logic */
        const values = field.conditional.split(" ");
        const key = values[0];
        const operator = values[1];
        const value = values[2];
        if (formData.data[key] !== value)
          showField = false;
      }
      /* Not sure if this validation logic works. Really about if I need showField to be true or false..Think it has to be required for it to matter  */ 
      if (typeof field.conditional1 !== 'undefined') {
        //     conditional: "AGENT_TYPE === DIALOGFLOW",
/* Need to finish this code to handle more logic */
        var variables = formData.data;
        formData.data["users"] = props.userDeep;
        showField = evaluateDynamicLogic(field.conditional1, variables);
      }
      if (field.required && showField && (!formData.data[field.name] || formData.data[field.name].length === 0)) {
        if (debug) console.log("Form invalid");
        fieldString += field.display_name + ", ";
        invalid = true;
      }
    });
    if (invalid) {
      if (fieldString.length > 2) setValidationString(fieldString.substring(0, fieldString.length - 2));
      return true;
    }
    else
      return false;
  }
  const getMyObjects = async () => {
     const response = await fetch("/api/getObjects/" + props.config.collection + "/where/userIds/array-contains/" + props.user)
     return await response.json();
  }
  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED"
      });

      /* adding another custom level of validation for objects */
      let valid = true;
      if (typeof props.validate_object !== 'undefined') {
         valid = props.validate_object(formData.data);
         if (debug) console.log("valid = " + JSON.stringify(valid));
         formData.data["status"] = valid.status;
      }
      if (formInvalid(formData) || form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
         setValidated(true);
         setFormData({
           ...formData,
           status: "VALIDATION_FAILURE"
         });
      }
      else {
        setValidated(false);
        var dataToSave = {}
        /* if we are in a subtable..pass the new record up to higher level to save */
        if (props.depth > 0 && props.top_level_save) {
           if (!("id" in formData.data)) {
              formData.data["id"] = uuid();
           }
           var found = false
           for (let x=0;x<props.input_data.length && !found;x++) {
               if (props.input_data[x]["id"] == formData.data["id"]) {
                  found = true;
                  props.input_data[x] = formData.data;
               }
           }
           if (!found) {
              props.input_data.push(formData.data);  
           }
           props.top_level_save("testPlan", props.input_data);
           return;
        }
        if ("row" in formData.data && typeof props.sub_array !== 'undefined') { /* this is an existing row when a subarray is involved  */
           const row = formData.data["row"];
           if (debug) console.log("ROW in Submit = " + row);
           originalData[row][props.sub_array] = formData.data;
           if (debug) console.log(JSON.stringify(originalData[row]));
           dataToSave = originalData[row]
        }
        else if (!("row" in formData.data) && typeof props.sub_array !== 'undefined') { /* this is a new row when a subarray is involved */
           dataToSave[props.sub_array] = formData.data
        }
        else {
           dataToSave = formData.data
        }
        dataToSave["user"] = props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
        const response = await fetch("/api/saveObject/" + props.config.collection, {
           headers: { "Content-Type": "application/json" },
           method: "post",
           //ORIG JUL 15, 2024body: JSON.stringify(formData.data)
           body: JSON.stringify(dataToSave)
        });
        const data = await response.json();
        if (data.success) {
           formData.data["id"] = data.id; /* KAD added this to populate the primary key in the UI after saving for the first time */
           if (debug) console.log(data);
           setFormData({
             data: { ...formData.data },
             status: "SUCCESS"
           });
           //props.setDisplayState("X"); /* KAD took this out Aug 19, 2024 */
           if (debug) console.log(JSON.stringify(formData));
 
           /* Go back and update the Tracking records */
           //setTimeout(() => { props.loadObject(data.id)}, 1000)

           props.setFormData(formData);
           if (props.scroll_to_top)
              props.scroll_to_top();

           if (formData.data.agentType === 'PDF_QA' && actionType === 'CREATE') { /* TODO: KAD added this Oct 5, 2024..need to generalize because can't be hardcoded */
              props.setDisplayState("UPLOAD_ATTACHMENT");
              //props.setActionType("UPLOAD");
           }
           else {
              if (formData.data.agentType === 'WEBSITE_QA' && actionType === 'CREATE') alert("Please now hit the \"Scan Website\" button");
              props.setDisplayState("DISPLAY");
              props.setActionType("DISPLAY");
           }

        } else {
          throw Error("Failed to add new " + props.config.name)
        }
      }
    } catch (e) {
      console.log(`Failed to add new ` + props.config.name + `: ${formData.data.agentType}`);
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR"
      });
    }
  };
  const handleCancel = async () => {
    props.setDisplayState("TABLE");
  }
  const evaluateRule = (displayRule, agentType) => {
      console.log("in evaluate Rule");
      const displayRuleSplitArray = displayRule.split(" ");
      var key = ""
      if (displayRuleSplitArray[0] === 'users.planName') 
         key = props.userDeep["planName"];
      const operator = displayRuleSplitArray[1];
      const value = displayRuleSplitArray[2];
      if (operator === '==') { 
         if (key === value) return true;
      }
      if (operator === '!=') { 
         if (key !== value) return true;
      }
      return false;
  }

  const setListData = async (fieldName, newList) => {
    setFormData({
      data: { ...formData.data, [fieldName]: newList },
      status: "IDLE"
    });
  }
  function isImage(fieldData) {
      if (typeof fieldData === 'undefined') return false;
      if ((fieldData.toLowerCase().endsWith(".png") || fieldData.toLowerCase().endsWith(".gif") || fieldData.toLowerCase().endsWith(".jpeg") || fieldData.toLowerCase().endsWith(".jpg") || fieldData.toLowerCase().endsWith(".webp") || fieldData.toLowerCase().endsWith(".svg"))) 
         return true;
      else 
         return false;
  }

  return (
    <Container>
      <Row>
        <Col>
          <TopMargin />
          <Form noValidate validated={validated}>
            {arrayChunk(props.config.fields, 3).map((row, i) => (
              <div key={i} className="row">
                {row.map((field, i) => {
                  //FROM ORIGINAL >>ABOVE 5 lines LEFTCURLYBRACKETprops.config.fields.mapLEFTPARENTTHESIS field => LEFTCURLYBRACKET 
                  if (typeof field.conditional !== 'undefined') {
                    //     conditional: "AGENT_TYPE === DIALOGFLOW",
                    const values = field.conditional.split(" ");
                    if (formData.data[values[0]] !== values[2])
                      return;
                  }
                  if (typeof field.conditional1 !== 'undefined') {
                    //     conditional: "AGENT_TYPE === DIALOGFLOW",
                     var variables = formData.data;
                     variables["users"] = props.userDeep;
                     const result = evaluateDynamicLogic(field.conditional1, variables);
                     if (!result) return;
                  }
                  if ("flags" in field && field.flags.indexOf("display") >= 0) return;
                  
                  /* for default Fields, we have to seed their data in the formData because there is no typing action to call handleInputChange */
                  if (actionType === 'CREATE' && field.default && field.default.length > 0) {
                      if (!(field.name in formData.data)) {
                        setFormData({
                          data: { ...formData.data, [field.name]: field.default },
                          status: "IDLE"
                        })
                      }
                  }

                  var attributes = {};
                  attributes["required"] = false;
                  if (field.required)
                    attributes["required"] = true;

                  //if (validated) /* This is a catch all for ALL validations ...prints out the nice red message under the input field  */
                  //   attributes["isInvalid"] = validated && validField(formData.data[field.name], field);

                  /* Handle setting the red error color for invalid fields */
                  attributes["isInvalid"] =  isInvalidField(formData.data[field.name], field);

                  if ("header" in field) {
                     return (
                         <>
                             <div key={field.header} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                                <div style={{fontSize: "20px", backgroundColor: "rgb(50, 161, 17)", color: "white", marginRight: "25px", marginTop: "10px", marginBottom:"10px", paddingLeft:"16px"}}>

                                    <b>{field.header}</b>
                                </div>
                             </div>
                         </>
                     )
                  }

                  if (field.input_type === "list") {
                    if (field.list_fields)
                       attributes["fields"] = field.list_fields;

                     return (
                        <>
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                        <List {...attributes} list={formData.data[field.name]} field_name={field.name} callback={setListData} />
                        </div>
                        </>
                     )
                  }
                  if (field.input_type === "checkbox") {
                    if (formData.data[field.name])
                       attributes["checked"] = true;

                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                          <Form.Control
                            {...attributes}
                            value={formData.data[field.name]}
                            type="checkbox"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={handleInputChange}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Click to disable
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    );
                  }
                  /*
                                  if (field.input_type === "display" && displayState === 'DISPLAY') { **** https://www.geeksforgeeks.org/react-bootstrap-form-validation/ **** ***** https://getbootstrap.com/docs/5.0/forms/validation/#:~:text=For%20custom%20Bootstrap%20form%20validation,form%20validation%20APIs%20in%20JavaScript. ***
                                  return (
                                     <>
                                     <Form.Group controlId={field.name}>
                                     <Form.Label style={props.style.displayName}>{field.display_name} {field.required === true? " (*) ":""} </Form.Label>
                                     <Form.Label style={props.style.displayName}>{formData.data[field.name] && formData.data[field.name].length > 0?": " + formData.data[field.name]:": Unknown"}</Form.Label>
                                     </Form.Group>
                                     </>
                                   );
                                 }
                                 if (field.input_type === "date" && field.flags.indexOf("display") >= 0 && displayState === 'DISPLAY') {
                                  return (
                                     <>
                                     <Form.Group controlId={field.name}>
                                     <Form.Label style={props.style.displayName}>{field.display_name} {field.required === true? " (*) ":""} </Form.Label>
                                     <Form.Label style={props.style.displayName}>{formData.data[field.name] && formData.data[field.name].length > 0?": " + formData.data[field.name].substring(0,10) :": Unknown"}</Form.Label>
                                     </Form.Group>
                                     </>
                                   );
                                 }
                  */
                  if (field.input_type === "color") {
                    /* Handle default fields */
                    if ((actionType === 'CREATE' || actionType === 'EDIT') && field.default && field.default.length > 0) {
                      if (!(field.name in formData.data)) {
                        setFormData({
                          data: { ...formData.data, [field.name]: field.default },
                          status: "IDLE"
                        })
                      }
                    }
                            //defaultValue={field.default}
                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                          <Form.Control
                            {...attributes}
                            value={formData.data[field.name]}
                            type="color"
                            name={field.name}
                            title={field.placeholder}
                            onChange={handleInputChange}
                            isInvalid={validated && isInvalidColor(formData.data[field.name])}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid {field.display_name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    );
                  }
                  if (field.input_type === "text") {
                    var textValue = "";
                    if (actionType === 'EDIT')
                      textValue = formData.data[field.name];

                    if (!("readonly" in field)) /* don't OVERWRITE an existing readonly setting in the config */
                       field["readonly"] = false;

                    /* Handle default fields */
                    if ((actionType === 'CREATE' || actionType === 'EDIT') && field.default && field.default.length > 0) {
                      textValue = field.default;
                      field["readonly"] = true
                      if (field.default.trim() === "{USER}" && "user" in props)
                        field.default = props.user;
                      if (field.default.trim() === "{ID}" && "remote_id" in props)
                        field.default = props.remote_id;

                      if (!(field.name in formData.data)) {
                        setFormData({
                          data: { ...formData.data, [field.name]: field.default },
                          status: "IDLE"
                        })
                      }
                    }
                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <div style={props.style.colorContainer}>
                            <Form.Label style={props.style.displayName}><div>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} {field.input_type === "color" && <PopoverPicker color={formData.data[field.name]} onChange={(e) => onColorChange(e, field.name)} />}</div></Form.Label>
                          {field.samples && 
            <>
           <DrawMenu field={field} /> </> }
                          </div>
                          <Form.Control
                            {...attributes}
                            readOnly={field["readonly"]}
                            value={formData.data[field.name]}
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={handleInputChange}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                             {field.error_message && field.error_message}
                             {!field.error_message && <>Please enter valid {field.display_name} field</>}
                          </Form.Control.Feedback>
                        </Form.Group>
         { isImage(formData.data[field.name]) && <img src={formData.data[field.name]} width="50px" /> }
                      </div>
                    );
                  }
                  if (field.input_type === "dropdown") {
                    var options = "<option></option>\n";
                    if (Array.isArray(field.values)) { /* just an array os strings..do nothing */
                        //field.values.forEach(value => { options += "<option>" + value + "</option>" }); THIS DIDN"T DO ANYTHING
                    } else if (field.values === 'myObjects') { /* this is for like the nextAgent code ...need to generalize this */
                       const myObjects = props.all_objects; 
                       field.values = [];
/* TODO: Can't have hardcoded stuff in SuperAppObject */
                       for (let x=0;x<myObjects.length;x++) {
                           if (myObjects[x]["id"] !== formData.data["id"] && (myObjects[x]["agentType"] === 'PDF_QA' || myObjects[x]["agentType"] === 'WEBSITE_QA')) /* don't add this id into the list */
                              field.values.push(myObjects[x]);
                       }
                    } else { /* TODO: Need to add the adminConfig search term..it's not a fall through */
                        const values = field.values.split(".");
                        if (values[0] === 'adminConfig') {
                           const agentTypes = globalContextData["data"]["agentTypes"];  
                           field.values = [];
                           for (let x=0;x<agentTypes.length;x++) {
                               const displayRule = agentTypes[x]["displayRule"];
                               const agentType = agentTypes[x]["agentType"];
                               var showValue = true;
                               if (typeof displayRule !== 'undefined') {
                                  showValue = evaluateRule(displayRule, agentType);
                               } 
                               if (showValue) {
                                  field.values.push(agentTypes[x]["agentType"]);
                               }
                           }
                        }
                    }

                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                          <Form.Control
                            {...attributes}
                            value={formData.data[field.name] || field.default}
                            as="select"
                            type="select"
                            name={field.name}
                            onChange={handleInputChange}
                            defaultValue={field.dafault}
                          >
                            <option></option>
                            <Options options={field.values}/>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select a {field.display_name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    );
                  }
                  if (field.input_type === "subTable") {
                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                      </div>
                    );
                  }
                  if (field.input_type === "html") {
                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                          <Editor value={formData.data[field.name]} onChange={(e) => onHTMLChange(e, field.name)} />

                        </Form.Group>
                      </div>
                    );
                  }
                  if (field.input_type === "textarea") {
                    return (
                      <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Group controlId={field.name}>
                          <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                          {field.samples && <DrawMenu field={field} /> }
                          <Form.Control as="textarea" rows={field.text_area_num_rows || 3}
                            {...attributes}
                            value={formData.data[field.name]}
                            name={field.name}
                            type="textarea"
                            placeholder={field.placeholder}
                            onChange={handleInputChange}>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                             {field.error_message && field.error_message}
                             {!field.error_message && <>Please enter valid {field.display_name} field</>}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    );
                  }
                  if (field.input_type === "image") {
                    return (
                      <UploadFile />
                    );
                  }
                  if (field.input_type === "none") {
                    return (
                      <div>{field.placeholder} </div>
                    );
                  }
                })
                }
              </div>
            ))}
            <RowMargin />
            <Button variant="primary" type="button" onClick={handleSubmit}>
              {props.config.buttonName}
            </Button>&nbsp;
            <Button variant="primary" type="button" onClick={handleCancel}>
              Cancel
            </Button>&nbsp;
            {actionType === 'EDIT' && canDeleteRow(props, props.config, formData.data) && (
              <Button variant="primary" type="button" onClick={props.handleDelete}>
                Delete
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      {formData.status === "SUBMITTED" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "ERROR" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="danger">
                Failed to add/update record, please try again later.
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "VALIDATION_FAILURE" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="danger">
                Failed to add/update record, required fields missing: {validationString}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="success">Successfully added!</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Container>
  )
}
/*
*/
