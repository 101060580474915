import React, { useContext }  from 'react';

import { Row, Col, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

const AdminFeedback = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
      config["feedback"] = {
        "collection": "feedback",
        "defaultSort": "kevin",
        "name": "Feedback",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "None",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "User",
            name: "user",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Feedback",
            name: "feedback",
            input_type: "text",
            default: "",
            required: true
          }
        ],
        "table": [
          { selector: (row) => row.user, name: "User", sortable: true, minWidth: "25%" },
          { selector: (row) => row.feedback, name: "Feedback", sortable: true, minWidth: "25%" }
        ],
        "filterField": "feedback",
        "exportFields": ["user", "feedback"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
  return (
              <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={superAppStyle.cardStyle} elevation={8}>
                    <SuperAppObject depth="1" style={superAppStyle} title="Feedback" config={config["feedback"]} user={props.user} userDeep={props.userDeep} collection_name="feedback" input_data={props.feedback} display_state="BASIC_TABLE" />
                  </Paper>
                </Grid>
              </Grid>
  );
};

export default AdminFeedback;
