// Sidebar.js
import React, { useState } from 'react';

import RowMargin from "./RowMargin";

const UserAdminSidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState("Billing");
  const [style, setStyle] = useState({
    border: "none",
    backgroundColor: "transparent" });

  const activeStyle = {
    border: "none",
    backgroundColor: "transparent",
    color: "#32a111"
  }

/*
              <div to={"home"} className={activeMenu === 'home' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('home') }} >Home</div>
*/
  return (
    <div>
      <RowMargin />
      <RowMargin />
      <ul>
        <li><button style={activeMenu === 'Billing' ? activeStyle:style} onClick={(e) => { setActiveMenu("Billing"); props.callback("Billing");}}>Billing</button></li>
        <li><button style={activeMenu === 'Statistics' ? activeStyle:style} onClick={(e) => { setActiveMenu("Statistics"); props.callback("Statistics")}}>Statistics</button></li>
      </ul>
    </div>
  );
};

export default UserAdminSidebar;
