import React from "react";

//Components
import RowMargin from "./RowMargin";

export default function NotLoggedInMessage(props) {

  return (
    <React.Fragment>
      {!props.user && props.showLoginErrorMessage &&
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px", color: "#32a111"}} >Welcome to our Agent/Chatbot Builder</h1>
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "200px" }}>
            Using this tool, you can create different types of Chatbots/Agents for your business website, or your organization's internal Intranets. Chatbots can be a great way to engage with your customers anonymously to learn about their needs and help them get their questions answered without having to talk with every customer.
          <RowMargin />
           <ul>
            <li> If you have an account already, please click on the Login link to access the tool</li> 
            <li> If you are a customer of WIX, we will be releasing our solution in the WIX marketplace shortly, however you can use our trial here to get started</li> 
            <li> If you want to create Agents for other websites (than WIX) or mobile devices, please login to get a trial account</li>
            <p></p>
           </ul>
            <div>Please note that you will need to log in with a business email address that matches your website's domain if you wish to build a Chatbot that answers questions from a scan of your website (like a Google Search of your website). The log in screen provides a way to create a new acccount and password to achieve this. You cannot use a Gmail account.</div>
            <p></p>
          </div>
          <RowMargin />
        </>
      }
    </React.Fragment>
  );
}
/*
            <li> If you are a customer of WIX, please click <a href="http://www.wix.com">here</a> to get access through the WIX marketplace</li> 
*/
