import React, {useEffect} from "react";
import { Button } from "react-bootstrap";
import { useMyContext } from '../MyContext';
import "../pages/Home.css"
import WixRedirectURL from './WixRedirectURL'



function PlanTable(props) {
  const myContext = useMyContext();
  var showFreemiumColumn = true;
  var showTrialColumn = true;

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
/*
       var instance = window.location.pathname.substring(window.location.pathname.lastIndexOf("=") + 1);
       console.log(window.location.pathname);
       const response = await fetch("/api/getWixInfo2/" + instance);
       const data = await response.json();
*/
    }
    loadComponent();
  })

  function canConnectToStripe() {
      if (myContext["data"]["environmentName"] === 'Development') return true;
      if (myContext["data"]["adminEmail1"] === props.user) return true;
      if (myContext["data"]["adminEmail2"] === props.user) return true;
      if (myContext["data"]["adminEmail3"] === props.user) return true;
      return false;
  }
  async function selectPlan(name) {
   console.log("n selectplan");
   var done = false;
   if (name === 'trial' || name === 'freemium') {
   }
     for (let x=0;x<props.planIds.length && !done;x++) {
         const plan = props.planIds[x];
console.log(plan)
         if (plan["name"] === name) {
             done = true;
             const response = await WixRedirectURL(plan);
             console.log(JSON.stringify(response));
             window.location = response.redirectSession.fullUrl;
         }
     }
  }
     if (props.currentPlanName === 'trial')
        showFreemiumColumn = true;
     return(
          <>
          <table border="2px" className="plantable">
             <tbody>
             <tr><td><b>Feature</b></td>{showTrialColumn && <td><b>Trial</b></td>}{showFreemiumColumn && <td><b>Freemium</b></td>}<td><b>Silver Plan</b></td><td><b>Gold Plan</b></td>{false && <td><b>Platinum Plan</b></td>}</tr>
             <tr><td>Max Agents</td>{ showFreemiumColumn && <td>5</td>}{ showFreemiumColumn && <td>1</td>}<td>1</td><td>5</td>{ false && <td>10</td>}</tr>
             <tr><td>Max Messages Per Month Per Agent (1)</td>{ showTrialColumn && <td>100</td>}{showFreemiumColumn && <td>100</td>}<td>1000</td><td>5000</td>{ false && <td>100000</td>}</tr>
             <tr><td>Max Combined File Size Per Agent</td>{ showTrialColumn && <td>1M</td>}{showFreemiumColumn && <td>N/A</td>}<td>25M</td><td>50M</td>{ false && <td>100M</td>}</tr>
             <tr><td>Type of Bots</td>{ showTrialColumn && <td>Any</td>}{showFreemiumColumn && <td>FAQ, Dialogflow/Vertex, Webhook</td>}<td>Any</td><td>Any</td>{ false && <td>Any</td>}</tr>
             <tr><td>Agent Chaining</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Automated Agent Testing</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Supports Production Workloads</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>Yes (1)</td>}<td>Yes</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Supports Dev/Test/Prod Environments</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Time Bound</td>{ showTrialColumn && <td>Yes - 30 Days</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>No</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Cost Per Month</td>{ showTrialColumn && <td>$0</td>}{showFreemiumColumn && <td>$0</td>}<td>$10</td><td>$50</td>{ false && <td>$500</td>}</tr>
             {props.showButtons &&
             <tr><td></td>
                 {showTrialColumn && <td style={{ textAlign: "center"}}>{props.currentPlanName === 'trial' && <>CURRENT</> }</td>}
                 {showFreemiumColumn && <td style={{ textAlign: "center"}}>{props.currentPlanName !== 'freemium' && <Button onClick={(e) => props.selectPlan("freemium")}>SELECT</Button>}{props.currentPlanName === 'freemium' && <>CURRENT</> }</td>}
                 <td style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium'  && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'silver' && <Button onClick={(e) => props.selectPlan("silver")}>SELECT</Button>}{props.currentPlanName === 'silver' && <>CURRENT</> }</td>
                 <td style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium' && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'gold' && <Button onClick={(e) => props.selectPlan("gold")}>SELECT</Button>}{props.currentPlanName === 'gold' && <>CURRENT</>}</td>
                 { false && <td style={{ textAlign: "center"}}>{true && <>Coming Soon</>}{canConnectToStripe() && props.currentPlanName !== 'platinum' && <Button onClick={(e) => props.selectPlan("platinum")}>SELECT</Button>}{props.currentPlanName === 'platinum' && <>CURRENT</>}</td>}
             </tr>
             }
             {props.showWixButtons &&
             <tr><td></td>
                 {showTrialColumn && <td style={{ textAlign: "center"}}>{props.currentPlanName === 'trial' && <>CURRENT</> }</td>}
                 {showFreemiumColumn && <td style={{ textAlign: "center"}}>{props.currentPlanName !== 'freemium' && <Button onClick={(e) => selectPlan("freemium")}>WIX SELECT</Button>}{props.currentPlanName === 'freemium' && <>CURRENT</> }</td>}
                 <td style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium'  && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'silver' && <Button onClick={(e) => selectPlan("silver")}>WIX SELECT</Button>}{props.currentPlanName === 'silver' && <>CURRENT</> }</td>
                 <td style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium' && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'gold' && <Button onClick={(e) => selectPlan("gold")}>WIX SELECT</Button>}{props.currentPlanName === 'gold' && <>CURRENT</>}</td>
                 { false && <td style={{ textAlign: "center"}}>{true && <>Coming Soon</>}{canConnectToStripe() && props.currentPlanName !== 'platinum' && <Button onClick={(e) => selectPlan("platinum")}>WIX SELECT</Button>}{props.currentPlanName === 'platinum' && <>CURRENT</>}</td>}
             </tr>
             }
             </tbody>
          </table>
          <div>(1) Note: Only messages sent by agent users count towards this count (i.e., user sends 5 questions, that equals 5 messages).</div>
          </>
      )
   }

export default PlanTable;
