import React, { useEffect, useState, useContext } from "react";

import GlobalContext from '../GlobalContext';
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

//Components
import RowMargin from "./RowMargin";
import Footer from "./Footer";

// generate select dropdown option list dynamically
function Options({ options }) {
  return (
    options.map(option =>
      <option>
        {option}
      </option>)
  );
}

export default function Registration(props) {
  const globalData = useContext(GlobalContext);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    data: props.userDeep,
    status: "IDLE"
  });
  var numEmployeeOptions = ["1-10", "10-100", "100-1000", "> 1000"];
  const [validationString, setValidationString] = useState(""); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */

  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };
  const formInvalid = (data) => {
    var invalid = false;
    var fieldString = "";
    
      if (!("question1" in data) || data["question1"].length === 0) {
        fieldString += "Question1, ";
        invalid = true;
      }
      if (!("question2" in data) || data["question2"].length === 0) {
        fieldString += "Question 2, ";
        invalid = true;
      }
      if (!("question3" in data) || data["question3"].length === 0) {
        fieldString += "Question 3, ";
        invalid = true;
      }
      if (!("question4" in data) || data["question4"].length === 0) {
        fieldString += "Question 4, ";
        invalid = true;
      }
    if (invalid) {
       if (fieldString.length > 2) setValidationString(fieldString.substring(0, fieldString.length - 2));
       return true;
    }
    else
      return false;
  }

  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      console.log("in handleSubmit()");
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED"
      });

      if (formInvalid(formData.data) || form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE"
        });
      }
      else {
        event.preventDefault();
        setValidated(false);
        //formData.data["planName"] = "trial";
        if (false) {
           console.log(formData.data);
           return;
        }
        const response = await fetch("/api/saveObject/users", {
          headers: { "Content-Type": "application/json" },
          method: "post",
          body: JSON.stringify(formData.data)
        });
        const data = await response.json();
        if (data.success) {
      console.log("in data success()");
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS"
          });
          props.setScreen(1);
          const response = await fetch("/api/verifyUser/" + data.id);
        } else {
      console.log("in data failure()");
          throw Error("Failed to register")
        }
      }
    } catch (e) {
      console.log(`Failed to add feedback`);
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR"
      });
    }
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setFormData({
        data: props.userDeep, 
        status: "IDLE"
      });
    }
    loadComponent();
  }, [props.userDeep]);

  function displayForm(status) {
     if (status !== 'SUCCESS')
        return true;
     return false;
  }

  return (
    <>
        <React.Fragment>
        {displayForm(formData.status) && 
          <>
          Thanks for your interest in the Gen AI Solutions Agent Builder.  Before we give you full access for 30 days to try our amazing technology, we need to ask a couple of quick questions.
          <Form noValidate validated={validated}>
            <Form.Group controlId="name">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="question1"
                onChange={handleInputChange}
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a company name 
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="companyName">
              <Form.Label>Website for your Chatbot/Agent</Form.Label>
              <Form.Control
                required
                type="text"
                name="question2"
                onChange={handleInputChange}
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a company name 
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="numEmployees">
              <Form.Label>Number Employees</Form.Label>
              <Form.Control
                required
                as="select"
                type="select"
                name="question3"
                onChange={handleInputChange}
              >
                <option></option>
                <Options options={numEmployeeOptions} />
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide an employee count 
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="feedback">
              <Form.Label>Can you explain why you are interested in our solution?</Form.Label>
              <Form.Control as="textarea" rows={3}
                required
                name="question4"
                onChange={handleInputChange}
                placeholder=""
              />
              <Form.Control.Feedback type="invalid">
                Please provide some reasons why you want to evaluate this solution 
              </Form.Control.Feedback>
            </Form.Group>
            <RowMargin />
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Form>
          </>
         }
        </React.Fragment>
      {formData.status === "VALIDATION_FAILURE" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="danger">
                Failed to save registration, required fields missing
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="success">Thank you for registering. Please check your inbox for an email from Gen AI Solutions, and click on the provided link to enable your trial</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  )
}
/*
Click <a href="#" onClick={() => window.location.href="/"}> to start your trial</a></Alert>
*/
