import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Components
import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import SuperAppObject from "../components//superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

export default function AdminStatistics(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [ countOfAgentTypes, setCountOfAgentTypes] = useState({});
  const [ countOfPlans, setCountOfPlans] = useState({});

  const loadStats = async () => {
    console.log("IN getStats - api/getStats")
    try {
      await fetch("api/getAdminStats")
        .then(response => response.json())
        .then(data => { 
           console.log("data = " + JSON.stringify(data));
           setCountOfAgentTypes(data["countOfAgentTypes"]);
           setCountOfPlans(data["countOfPlans"]);
        })
   }
   catch (e) {
       console.log(`Failed to loadStats`);
       console.log(e);
       console.log(e.stack);
   }
 }

  useEffect(() => {
    //console.log("IN HERE AdminStatistics UseEffect");
    async function loadComponent() {
      await loadStats();
    }
    loadComponent();
  }, []);

  return (
      <React.StrictMode>
         <Container ref={containerRef} fluid>
            <Row>
               <Col style={superAppStyle.masterPageStyle}>
   {props.user && props.user.length > 0 &&
    <React.Fragment>
    <h3>Statistics</h3>
    <div>Count of Plans: {JSON.stringify(countOfPlans)}</div>
    <RowMargin />
    <div>Count of Agent Types: {JSON.stringify(countOfAgentTypes)}</div>
    </React.Fragment>
   }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
            </Col>
         </Row>
      </Container>
      </React.StrictMode>
  );
}
