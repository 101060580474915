/* https://dev.wix.com/docs/rest/business-management/payments/wix-payments-provider/transactions/introduction */

import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import Registration from "../components/Registration";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import PlanTable from "../components/PlanTable";
import MyCheckoutForm from "../components/MyCheckoutForm";
import WixSubscriptions from "../components/WixSubscriptions";
import Stripe from "../components/Stripe";
import GlobalContext from '../GlobalContext';
import { useMyContext } from '../MyContext';
import "./Home.css"

/* STRIPE STUFF */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//const stripePromise = loadStripe("pk_test_51Q0CbMRpm2Dt6FkHg4EP7bfClb11EFqtBSWOeKqgWhAzSfwKYNG8oZ9NSUwdIFRKemJzx3iEZA3qKcOvJMtbbwdv00gONaoWp0");
//https://www.mdfaisal.com/blog/how-to-integrate-stripe-with-a-react-application


export default function Home(props) {
  const myContext = useMyContext();
  const [ screen, setScreen ] = useState(props.screen || 1);
  const [ currentPlanName, setCurrentPlanName ] = useState(props.userDeep.planName);
  const [ allPlansData, setAllPlansData ] = useState([]);
  const [ newPlanData, setNewPlanData ] = useState([]);
  const [ checkOutURL, setCheckOutURL ] = useState([]);
  const [ stripeSessionId, setStripeSessionId ] = useState("");
  const [ stripeCustomerId, setStripeCustomerId ] = useState("");
  const [ trialNumDaysLeft, setTrialNumDaysLeft ] = useState(props.userDeep.trialLength || 100);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [ userData, setUserData ] = useState({
    data: props.userDeep,
    status: "IDLE"
  });

  function extractValueFromURL(str, key) {
     var returnStr = "";
     if (typeof str.length === 'undefined') return ""
     if (str.indexOf(key) >= 0)  {
        returnStr = str.substring(str.indexOf(key) + key.length + 1);
        let endPosition = returnStr.indexOf("&");
        if (endPosition >= 0)
           returnStr = returnStr.substring(0, endPosition);
     }
     return returnStr;
  }
  function getUser() {
          fetch("/api/getUser/" + props.user)
           .then((userResponse) => userResponse.json()).
           then(data => {
              setUserData({
                data: data,
                status: "IDLE"
              });
          })
   }

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
       /* const response = await fetch("/api/getObjects/plans"); */
       const response = await fetch("/api/getPlans");
       const planDataArray = await response.json();
       setAllPlansData(planDataArray);

       //console.log("session = " + props.userDeep.setStripeSessionId)
   try {
       if (props.userDeep.stripeSessionId && props.userDeep.stripeSessionId.length > 0) {
          setStripeSessionId(props.userDeep.stripeSessionId);
       }
   } catch (e) {
      console.error(e);
   }

//console.log("userDeep = " + JSON.stringify(props.userDeep));
//console.log("user = " + props.user);

/*
       if (Object.keys(props.userDeep).length === 0 && props.user.length > 0) {
         const response = await fetch("/api/getUser/" + props.user);
         const data = await response.json();
 console.log(data);
         if (Object.keys(data).length === 0 && props.user.length > 0) {
            setTimeout(getUser, 2000);
         }
         else {
            setUserData({
              data: data,
              status: "IDLE"
            });
         }
      }   
*/
            setUserData({
              data: props.userDeep,
              status: "IDLE"
            });
      var d1 = new Date(props.userDeep.creationDate), d2 = new Date(); 
      setTrialNumDaysLeft(props.userDeep.trialLength - Math.floor((d2 - d1)/60000/24/60));
      setCurrentPlanName(props.userDeep.planName);
  
      const query = (new URLSearchParams(window.location.search)) + "";
      if (query.indexOf("screen") >= 0 && typeof props.userDeep !== 'undefined' && props.userDeep && "userId" in props.userDeep) {
        setCurrentPlanName(extractValueFromURL(query, "plan_name"));
        setStripeSessionId(extractValueFromURL(query, "session_id"));
        console.log("session = " + extractValueFromURL(query, "session_id"));
        await confirmBilling(extractValueFromURL(query, "session_id"), props.userDeep );
        setScreen(4);
      }
    }
    loadComponent();
  }, [props.userDeep]);

  async function prevScreen() {
     setScreen(screen - 1);
  }
  async function nextScreen() {
     setScreen(screen + 1);
  }

  async function updateBilling() {
     console.log("in updateBilling");
     const body = {}
     body["planName"] = newPlanData["planName"]   
     body["stripeCustomerId"] = props.userDeep["stripeCustomerId"] 
     console.log("in updateBilling - " + newPlanData["planName"])
     console.log("in updateBilling - " + props.userDeep["userId"]);
     const response2 = await fetch("/api/updateSubscription", {
           headers: { "Content-Type": "application/json" },
           method: "post",
           body: JSON.stringify(body)
     });
     const data2 = await response2.json();
     if (data2.success) {
        await confirmBilling("", props.userDeep);
     }
  }

  async function confirmBilling(sessionId, user) {
     if (user && "planName" in user) {
        user["subscribed"] = user["proposedPlan"]["subscribed"]; 
        user["planName"] = user["proposedPlan"]["planName"]; 
        user["maxAgents"] = user["proposedPlan"]["maxAgents"];
        user["maxMessageCount"] = user["proposedPlan"]["maxMessageCount"];
        user["maxFileSize"] = user["proposedPlan"]["maxFileSize"];
        user["maxFAQLength"] = user["proposedPlan"]["maxFAQLength"];
        user["subscriptionDate"] = new Date().toISOString(); 
        if (typeof sessionId !== "undefined" && sessionId.length > 0)
        {
           user["stripeSessionId"] = sessionId; 
           //userData.data["stripeCustomerId"] = stripeCustomerId; 
           user["paymentInfoLoaded"] = true; 
        }
        user["billingLive"] = true; 
   
     }
     console.log("user = " + JSON.stringify(user));

     if ("id" in user) {
        /* save the form */
        const response2 = await fetch("/api/saveObject/users", {
              headers: { "Content-Type": "application/json" },
              method: "post",
              body: JSON.stringify(user)
        });
        const data2 = await response2.json();
        if (data2.success) {
           alert("Plan was updated to " + capitalize(user["planName"]) + "");
           setScreen(3);
        }
        else {
           alert("Plan was not updated");
        }
     }
     else {
        alert("Plan was not updated");
     }
  }

  async function redirectToCheckOut() {
        let body = {};
        body["planName"] = newPlanData["planName"];
        body["userId"] = props.userDeep["userId"] 
console.log(body["planName"]);
        const response3 = await fetch("/api/create-checkout-session", {
              headers: { "Content-Type": "application/json" },
              method: "post",
              body: JSON.stringify(body)
        });
        const data3 = await response3.json();
        console.log(data3);
        setStripeCustomerId(data3["stripeCustomerId"]);
        window.location.href = data3["url"]
  }
  async function selectPlan(planName) {
     /* first load the specifics of the plan that was selected */
/* 
     const response = await fetch("/api/getObjects/plans/where/planName/==/" + planName) 
     const planDataArray = await response.json();
     const planData = planDataArray[0];
*/
     const response = await fetch("/api/getPlanByName/" + planName) 
     const planData = await response.json();
     setNewPlanData(planData);

     const response1 = await fetch("/api/getAgentCount/" + props.user) 
     const responseData = await response1.json();
     const numAgents = responseData["numAgents"];
     if (numAgents > planData["maxAgents"]) {
        window.alert("You cannot move to this plan as you have created too many agents.  Please either delete the agents you don't want or pick a plan that supports this many agents. If you want to keep your work, you can use the Export button to download a file which you could import in at a later date");
        return;
     }

     /* Add the data into the form */
     userData.data["proposedPlan"] = {};
     if (planName !== 'trial') { /* can only set subscribed if you are not in trial */
        userData.data["proposedPlan"]["subscribed"] = true 
     }
     else { /* this code should never get executed if things are working properly */
        userData.data["proposedPlan"]["subscribed"] = false 
     }
     userData.data["proposedPlan"]["planName"] = planName 
     userData.data["proposedPlan"]["maxAgents"] = planData["maxAgents"] 
     userData.data["proposedPlan"]["maxMessageCount"] = planData["maxMessageCount"] 
     userData.data["proposedPlan"]["maxFileSize"] = planData["maxFileSize"] 
     userData.data["proposedPlan"]["maxFAQLength"] = planData["maxFAQLength"] 
     
     /* save the form */
     if ("id" in userData.data) {
        const response2 = await fetch("/api/saveObject/users", {
           headers: { "Content-Type": "application/json" },
           method: "post",
           body: JSON.stringify(userData.data)
        });
        const data = await response2.json();
        if (data.success) {
           setScreen(screen + 1);
        }
        else {
           alert("Proposed Plan was not updated");
        }
     }
     else {
         alert("Proposed Plan was not updated");
     }
  }
  const capitalize = (str) => {
     if (typeof str === 'undefined') return "Undefined";
     if (str.length < 2) return str;
     str = str.substring(0,1).toUpperCase() + str.substring(1);
     return str;
  }
  const AgentList = () => {
      return(
           <ul>
            <li>FAQ Agent - an agent that will answer frequently asked questions</li> 
            <li>PDF QA Agent - an agent that will answer questions about uploaded PDF documents</li>
            <li>Website QA Agent - an agent that will answer questions about scanned websites that you maintain</li>
            <li>Dialogflow Agent - an agent that will connect to a Dialogflow or Vertex AI Agent that you created in Google</li>
            <p></p>
           </ul>
      )
  }
  function canConnectToStripe() {
      if (myContext["data"]["environmentName"] === 'Development') return true;
      if (myContext["data"]["adminEmail1"] === props.user) return true;
      if (myContext["data"]["adminEmail2"] === props.user) return true;
      if (myContext["data"]["adminEmail3"] === props.user) return true;
      return false;
  }
  const PlanTableX = () => {
     var showFreemiumColumn = true;
     var showTrialColumn = true;
     if (currentPlanName === 'trial')
        showFreemiumColumn = true;
     return(
          <>
          <table border="2px" className="plantable">
             <tbody>
             <tr><td><b>Feature</b></td>{showTrialColumn && <td><b>Trial</b></td>}{showFreemiumColumn && <td><b>Freemium</b></td>}<td><b>Silver Plan</b></td><td><b>Gold Plan</b></td>{false && <td><b>Platinum Plan</b></td>}</tr>
             <tr><td>Max Agents</td>{ showFreemiumColumn && <td>5</td>}{ showFreemiumColumn && <td>1</td>}<td>1</td><td>5</td>{ false && <td>10</td>}</tr>
             <tr><td>Max Messages Per Month Per Agent (1)</td>{ showTrialColumn && <td>100</td>}{showFreemiumColumn && <td>100</td>}<td>1000</td><td>5000</td>{ false && <td>100000</td>}</tr>
             <tr><td>Max Combined File Size Per Agent</td>{ showTrialColumn && <td>1M</td>}{showFreemiumColumn && <td>N/A</td>}<td>25M</td><td>50M</td>{ false && <td>100M</td>}</tr>
             <tr><td>Type of Bots</td>{ showTrialColumn && <td>Any</td>}{showFreemiumColumn && <td>FAQ, Dialogflow/Vertex, Webhook</td>}<td>Any</td><td>Any</td>{ false && <td>Any</td>}</tr>
             <tr><td>Agent Chaining</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Automated Agent Testing</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Supports Production Workloads</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>Yes (1)</td>}<td>Yes</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Supports Dev/Test/Prod Environments</td>{ showTrialColumn && <td>No</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>Yes</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Time Bound</td>{ showTrialColumn && <td>Yes - 30 Days</td>}{showFreemiumColumn && <td>No</td>}<td>No</td><td>No</td>{ false && <td>Yes</td>}</tr>
             <tr><td>Cost Per Month</td>{ showTrialColumn && <td>$0</td>}{showFreemiumColumn && <td>$0</td>}<td>$10</td><td>$50</td>{ false && <td>$500</td>}</tr>
             <tr><td></td>
                 {showTrialColumn && <td style={{ textAlign: "center"}}>{currentPlanName === 'trial' && <>CURRENT</> }</td>}
                 {showFreemiumColumn && <td style={{ textAlign: "center"}}>{currentPlanName !== 'freemium' && <Button onClick={(e) => selectPlan("freemium")}>SELECT</Button>}{currentPlanName === 'freemium' && <>CURRENT</> }</td>}
                 <td style={{ textAlign: "center"}}>{currentPlanName === 'freemium'  && <>Coming Soon</>}{canConnectToStripe()  && currentPlanName !== 'silver' && <Button onClick={(e) => selectPlan("silver")}>SELECT</Button>}{currentPlanName === 'silver' && <>CURRENT</> }</td>
                 <td style={{ textAlign: "center"}}>{currentPlanName === 'freemium' && <>Coming Soon</>}{canConnectToStripe()  && currentPlanName !== 'gold' && <Button onClick={(e) => selectPlan("gold")}>SELECT</Button>}{currentPlanName === 'gold' && <>CURRENT</>}</td>
                 { false && <td style={{ textAlign: "center"}}>{true && <>Coming Soon</>}{canConnectToStripe() && currentPlanName !== 'platinum' && <Button onClick={(e) => selectPlan("platinum")}>SELECT</Button>}{currentPlanName === 'platinum' && <>CURRENT</>}</td>}
             </tr>
             </tbody>
          </table>
          <div>(1) Note: Only messages sent by agent users count towards this count (i.e., user sends 5 questions, that equals 5 messages).</div>
          </>
      )
   }
  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setUserData({
      data: { ...userData.data, [name]: value },
      status: "IDLE"
    });
  };
     function buttonName() {
         
          if (newPlanData["planName"] === 'freemium') return "Accept";
          if (newPlanData["planName"] !== 'freemium' && props.userDeep.paymentInfoLoaded  === false) return "Checkout";
          if (newPlanData["planName"] !== 'freemium' && !("paymentInfoLoaded" in props.userDeep)) return "Checkout";
          if (newPlanData["planName"] !== 'freemium' && props.userDeep.paymentInfoLoaded  === true) return "Confirm Plan Change";
     }
  return (
    <React.Fragment>
      {userData && userData.data.planName === 'unregistered' && screen === 1 &&
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px", color: "#32a111"}} >Gen AI Registration</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
         <p></p>
          <Registration userDeep={props.userDeep} setScreen={setScreen} />
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
      {props.user && props.userDeep && typeof userData.data["planName"] !== "undefined" && props.userDeep.planName !== 'trial' && props.userDeep.planName !== 'unregistered' && screen === 1 &&
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px", color: "#32a111"}} >Subscriptions</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
             You are currently subscribed to the {capitalize(userData.data["planName"])} plan.  <p></p><p>If you need more capacity, feel free to upgrade to one of our bigger packages (Coming Soon!!):</p>
             <RowMargin/>
             {myContext["data"]["marketingMessage"] }
             <RowMargin/>
             <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan}/>
             <RowMargin/>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
      {props.user && props.userDeep &&  props.userDeep.planName === 'trial' && screen === 1 &&
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px", color: "#32a111"}} >Welcome to your Agent/Chatbot Builder Trial</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "300px" }}>
           {props.userDeep.trialOver &&
             <font color="red">Your trial has expired.  Tomorrow we will archive all of your agents in preparation for deletion in 30 days. If you'd like to purchase a plan, you can review the list below and pick one. If you need more time, please send us a note at info@genaisolutions.ai to request more time. Please specify how much time you need, and why you need the additional time.</font>
           }
           {!props.userDeep.trialOver &&
           <>You have {trialNumDaysLeft} days left to try out all our different types of agents
           <AgentList />
          <RowMargin />
          During this trial, you will be allowed to create up to {props.userDeep.maxAgents} agents of any type; however, you will NOT be able to put these agents into any production sites.  The software provides a way for you to see and interact with your chatbot/agent.  You will also be limited by the size of the data that you are able to store. Other than these limitations, the agents should be fully functional.  We also have some demos on the main website which can aid in your evaluation.
            <p></p>
         If you have done your evaluation, please use the following table to evaluate our various plans.  Click on the "SELECT" button for the plan you want to choose.
            </>
           }
         <p></p>
         <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan}/>
         <p></p>
             {myContext["data"]["marketingMessage"] }
         <p></p>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
     {props.user && screen === 2 && 
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px"}} >Consent to Bill</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
          You have selected the <b>{capitalize(newPlanData["planName"])}</b> plan.  To checkout and enter your billing information, please click the "{buttonName()}" button. Your subscription will continue until you cancel the service.  Yout billing will be not be prorated so if you don't cancel before your billing day of month, you will have to pay the entire month.
          <RowMargin /> 
          <div style={{textAlign: "center"}}>
          <Button name="Submit" onClick={prevScreen}>Back</Button> &nbsp;
          {newPlanData["planName"] === 'freemium' &&
             <Button name="Submit" onClick={() => confirmBilling("", userData.data)}>Accept</Button>
          }
          {newPlanData["planName"] !== 'freemium' && props.userDeep.paymentInfoLoaded  === false &&
             <Button name="Submit" onClick={redirectToCheckOut}>Checkout</Button>
          }
          {newPlanData["planName"] !== 'freemium' && !("paymentInfoLoaded" in props.userDeep) &&
             <Button name="Submit" onClick={redirectToCheckOut}>Checkout</Button>
          }
          {newPlanData["planName"] !== 'freemium' && props.userDeep.paymentInfoLoaded  === true &&
             <Button name="Submit" onClick={updateBilling}>Confirm Plan Change</Button>
          }
          </div> 
          <RowMargin />
          </div>
          {props.showLoginErrorMessage && <Footer />}
         
        </>
     }
     {props.user && screen === 3 && 
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px"}} >Billing Screen</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
      <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to {capitalize(newPlanData["planName"])} plan successful!</h3>
        </div>
      </div>
      {newPlanData["planName"] !== 'freemium' &&
      <form action="/api/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={stripeSessionId}
        />
        <Button id="checkout-and-portal-button" type="submit">
          View your billing information
        </Button> &nbsp;
      </form>
      }
      <RowMargin />
        <Button id="continue-button" type="submit" onClick={() => window.location.href="/"}>
          Continue 
        </Button>
    </section>

          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
     {props.user && screen === 4 && 
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px"}} >Billing Screen</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
             <Stripe />
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
     {props.user && screen === 5 && 
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px"}} >Consent to Bill</h1> 
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
            Congratulations! You are now configured to go LIVE with 1 chatbot/agent into production (WHAT TO DO ABOUT ALL THE OTHER CHAT BOTS THEY CREATED???)
          </div>
          <RowMargin />
          {props.showLoginErrorMessage && <Footer />}
        </>
     }
     <NotLoggedInMessage {...props} />
    </React.Fragment>
  );
}
/*
    Current Plan = {capitalize(currentPlanName)}
          <form>
            <select name="planName" onChange={handleInputChange}>
              <option selected value=""></option>
              { currentPlanName !== "freemium" && <option value="freemium">Freemium</option>}
              { currentPlanName !== "silver" && <option value="silver">Silver</option>}
              { currentPlanName !== "gold" && <option value="gold">Gold</option>}
              { currentPlanName !== "platinum" && <option value="platinum">Platinum</option>}
            </select>
          </form> 
          <Button name="Submit" onClick={handleClick}>Submit</Button>
    <WixSubscriptions />
    <Elements stripe={stripePromise}>
      <MyCheckoutForm />
    </Elements>
             <div><Button name="Submit" onClick={confirmBilling}>Accept</Button></div>
in screen 2
          {props.userDeep.billingLive === true &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={() => updateBilling()}>Accept</Button></div>
          }
          {props.userDeep.billingLive === false &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={getBillingURL}>Proceed to Billing Information</Button></div>
          }
  async function updateBilling() {
     * CONTACT BILLING FOR NEXT MONTH *
     await confirmBilling(); * use same method to change the plan *
     setScreen(4);
  }
*/
