import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Components
import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import SuperAppObject from "../components//superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

export default function Statistics(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [ count, setCount] = useState(0);
  const [ failedMessages, setFailedMessages] = useState([]);
  const [ stripeSessionId, setStripeSessionId] = useState(props.userDeep.stripeSessionId);

  const loadStats = async (userId, agentId) => {
    console.log("IN getStats - api/getStats - " + userId + "," + agentId)
    try {
      await fetch("api/getStats/" + userId + "/" + agentId)
        .then(response => response.json())
        .then(data => { 
           console.log("data = " + JSON.stringify(data));
           setFailedMessages(data["failed_messages"]);
           setCount(data["count"]);
        })
   }
   catch (e) {
       console.log(`Failed to loadStats`);
       console.log(e);
       console.log(e.stack);
   }
 }
function getConfig() {
    var configObj = {};
    try {
         configObj["failed_messages"] = {"collection": "input_data", 
                    "defaultSort": "kevin",
                    "name": "Messages",
                    "showLikes": false,
                    "buttonName": "Save",
                    "security": {
                        "create": "AllUsers",
                        "edit": "AllUsers",
                        "delete": "None",
                        "view": "AllUsers",
                    },
                    flags: "",
                    "fields": [
                      {
                         placeholder: "",
                         display_name: "User ID",
                         name: "user_id",
                         input_type: "display",
                         default: "",
                         required: false
                      },
                      {
                         placeholder: "",
                         display_name: "Agent ID",
                         name: "agent_id",
                         input_type: "display",
                         default: "",
                         required: false
                      },
                      {
                         placeholder: "",
                         display_name: "Request",
                         name: "request",
                         input_type: "text",
                         default: "",
                         required: true
                      },
                      {
                         placeholder: "",
                         display_name: "Response",
                         name: "response",
                         input_type: "text",
                         default: "",
                         required: true
                      },
                      {
                         placeholder: "",
                         display_name: "Date",
                         name: "log_timestamp",
                         input_type: "text",
                         default: "",
                         required: true
                      },
                    ],
                    "table": [ 
                              {selector: (row) => row.user_id, name: "User ID", sortable:true, width: "10%"},
                              {selector: (row) => row.agent_id, name: "Agent ID", sortable:true, width: "20%"},
                              {selector: (row) => row.request, name: "Request", wrap: true, sortable:true, width: "20%"},
                              {selector: (row) => row.response, name: "Response", wrap: true,  sortable:true, wrap: true, width: "50%"}
                    ],
                    "filterField": "response",
                    "exportFields": ["request", "response"],
                    "otherTableOptions": {"paginationPerPage":10},
                   }
    }
    catch(e) {
       console.log("Failed to configure: " + e);
       console.log(e.stack);
    } 
    return configObj;
}
const config = getConfig();

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      await loadStats(props.user, props.agentId);
    }
    loadComponent();
  }, []);

  return (
      <React.StrictMode>
         <Container ref={containerRef} fluid>
            <Row>
               <Col style={superAppStyle.masterPageStyle}>
   {props.user && props.user.length > 0 &&
    <React.Fragment>
    <h3>Statistics</h3>
    Total Log Messages: {count}
    <div width="100%">
    <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
       <Grid item xs={12}>
	   <Paper style={superAppStyle.cardStyle} elevation={8}>
    <SuperAppObject style={superAppStyle} title="Failed Messages - Use this data to improve your training data and answer more questions" config={config["failed_messages"]} user={props.user} userDeep={props.userDeep} collection_name="messages" input_data={failedMessages} display_state="STATS_TABLE" canEdit="false" />
	  </Paper>
       </Grid>
    </Grid>
    </div>
    </React.Fragment>
   }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
            </Col>
         </Row>
      </Container>
      </React.StrictMode>
  );
}
