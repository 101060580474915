import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function List(props) {
  const [items, setItems] = useState(props.list || []);
  const [fields, setFields] = useState(props.fields || []);
  const [field, setField] = useState(props.fields && props.fields.length > 0? props.fields[0]:"");
  const [showInputButton, setShowInputButton] = useState(false);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE"
  });

  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };

  const showInput = () => {
      setShowInputButton(true);
  };
  const handleAdd = (e) => {
    e.preventDefault();
        
    if (fields.length === 0) {
       if ("add_value" in formData.data && formData.data.add_value.length === 0) return;
       items.push(formData.data.add_value);
       setFormData({
         data: {"add_value": ""},
         status: "IDLE"
       });
     }
     else {
        items.push(formData.data);
     }
     setItems(items);
     console.log("Items = " + JSON.stringify(items));
     props.callback(props.field_name, items)
  };

  const handleRemove = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    props.callback(props.field_name, newItems)
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    props.callback(props.field_name, newItems)
  };
  function getInputFields() {
    var returnValue = [] 
    for (let x=0;x<fields.length;x++) {
       const field = fields[x];
       returnValue.push(<td><input name={field} onChange={handleInputChange}></input></td>) 
    }
    return returnValue
     
  }
  function getFieldHeaders() {
    var returnValue = [] 
    for (let x=0;x<fields.length;x++) {
       const field = fields[x];
       returnValue.push(<td><b><i>{field}</i></b></td>) 
    }
    return returnValue
     
  }

  return (
    <div>
      {!showInputButton && <button onClick={showInput}>+</button>}
      <>
      {showInputButton && fields.length === 0 && <form><input name="add_value" value={formData.data.add_value} onChange={handleInputChange}></input><button onClick={handleAdd}>Submit</button></form>}
      {showInputButton && fields.length > 1 &&
          <>
          <form>
             <table>
                <tr>{getFieldHeaders()}</tr>
                <tr>{getInputFields()}</tr>
            </table>
          <button onClick={handleAdd}>Submit</button>
          </form>
       </>}
      </>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={field.length === 0?item:item[field]} draggableId={field.length === 0?item:item[field]} index={index}>
                  {(provided) => (
                    <li 
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps} 
                      ref={provided.innerRef}
                    >
                      {fields.length === 0 && <>{item}</>}
                      {fields.length > 0 && <>{item[field]}</>}
                      &nbsp;<button onClick={() => handleRemove(index)}>X</button>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

/*
*/
export default List;
