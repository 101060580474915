import React, { useContext }  from 'react';

import { Row, Col, Button } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

const Users = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
      config["users"] = {
        "collection": "users",
        "defaultSort": "kevin",
        "name": "Users",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "admin",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "User ID",
            name: "userId",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Roles",
            name: "roles",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Plan Name",
            name: "planName",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Max Agents",
            name: "maxAgents",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Max Message Count (per month)",
            name: "maxMessageCount",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Max File Upload Size (MB)",
            name: "maxFileSize",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Max FAQ Length",
            name: "maxFAQLength",
            input_type: "text",
            validate_number: true,
            default: "",
            required: true
          }
        ],
        "table": [
          { selector: (row) => row.userId, name: "User", sortable: true, minWidth: "25%" },
          { selector: (row) => row.planName, name: "Plan Name", sortable: true, minWidth: "25%" },
          { selector: (row) => row.roles, name: "Roles", sortable: true, minWidth: "25%" }
        ],
        "filterField": "userId",
        "exportFields": ["userId", "roles"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
  return (
              <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={superAppStyle.cardStyle} elevation={8}>
                    <SuperAppObject depth="1" style={superAppStyle} title="Users" config={config["users"]} user={props.user} userDeep={props.userDeep} collection_name="users" display_state="TABLE" />
                  </Paper>
                </Grid>
              </Grid>
  );
};

export default Users;
