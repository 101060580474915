import React, { useEffect, useState, useContext } from "react";
import GlobalContext from './GlobalContext';
import { useMyContext } from './MyContext';
import { isMobile } from 'react-device-detect';


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Components
import Header from "./components/Header";
import UserRoute from "./components/UserRoute";
import ChatBot from "./components/ChatBot";
import WixLoginCallback from "./components/WixLoginCallback";
import PlanTable from "./components/PlanTable";
import Subscribe from "./components/Subscribe";
import Subscriptions from "./components/Subscriptions";
import WixGetPlans from "./components/WixGetPlans";
import Stripe from "./components/Stripe";
import Image from 'react-bootstrap/Image';

//Pages
import Home from "./pages/Home";
import Agents from "./pages/Agents";
import UserAdmin from "./pages/UserAdmin";
import Help from "./pages/Help";
import Contact from "./pages/Contact";
import Admin from "./pages/Admin";
//import Home from "./pages/Home";
//import SearchResults from "./pages/SearchResults";
//import User from "./pages/User";
import "./index.css";


function App() {
  const [id, setId] = useState("");
  const [user, setUser] = useState("");
  const [userDeep, setUserDeep] = useState({});
  const [iframeLogin, setIframeLogin] = useState(false); /* TODO: Think this might be better to call it WixSession or something like that */
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
  const globalData = useContext(GlobalContext);
  const { data, setData } = useMyContext();
  const [ planIds, setPlanIds ] = useState([]);
  var pathName = window.location.pathname.substring(1);


  const [users, setUsers] = useState({
    data: [],
    status: "IDLE"
  });
  const [reference, setReference] = useState({
    data: [],
    status: "IDLE"
  });

  setTimeout(() => setShowLoginErrorMessage(true), 2000) /* We wait because we need to give system time to get the User info..and we don't want to switch screens */

  const [chatWindowState, setChatWindowState] = useState(false);

  const toggleChatWindow = () => {
    if (!chatWindowState)
       setChatWindowState(true);
    else
       setChatWindowState(false);
  };

  async function loadUser() {
    try {
      const response = await fetch("/api/user");
      const data = await response.json();
      if (globalData.debug) console.log("XXXY= " + data.user);
      setUser(data.user);
      //await loadUserDeep(data.user);
    } catch (e) {
      console.log("Failed to load user!");
      setUser("");
    }
  }
  async function loadUserDeep(userId) {
    try {
      if (globalData.debug) console.log("#######User = " + userId);
      const response = await fetch("/api/getUser/" + userId);
      const data = await response.json();
      setUserDeep(data);
/*
      const response = await fetch("/api/getObjects/users/where/userId/==/" + userId);
      const data = await response.json();
      setUserDeep(data[0]);
*/
    } catch (e) {
      console.log("Failed to load user!");
      setUser({});
    }
  }
  async function loadReference() {
    try {
      const response = await fetch("/api/getObjects/reference");
      const data = await response.json();
      setReference({ data: data, status: "IDLE" });
    } catch (e) {
      console.log("Failed to load Reference!");
    }
  }
  /* This is the MAIN function to set the userDeep object....mentions of other ways to populate are commented out...*/ 
  async function registerLoggedInUser(userId) {
    try {
      setUser(userId);
      const response1 = await fetch("/api/addUser/" + userId);
      const data = await response1.json();
      //console.log(JSON.stringify(data["userObject"]));
      setUserDeep(data["userObject"]);
      const response2 = await fetch("/api/createSession/" + userId);
      await response2.json();

    } catch (e) {
      console.log("Failed to create session!");
    }
  }

  useEffect(() => {
    async function loadPage() {
       const urlSearchParams = new URLSearchParams(window.location.search);
       const params = Object.fromEntries(urlSearchParams.entries());
       try {
          const response = await fetch("/api/getAdminConfig");
          const data = await response.json();
          //setData(data);
       }
       catch (e) {
          console.log(`Failed to load adminConfig`);
          console.log(e);
          console.log(e.stack);
       }

       /* this parameter instance is the key to going back to WIX and figuring out the Logged in User */
       if ("instance" in params && window.location.pathname.indexOf("plan_table_with_buttons") < 0) {
          const response = await fetch("/api/getWixInfo/" + params["instance"]);
          const data = await response.json();
          //console.log("DATA = " + JSON.stringify(data));
          setUser(data["user"]);
          setId(data["id"]);
          setIframeLogin(true);
/* KAD TOOK THIS OUT OCT 10, 2024 because session is supposed to be an object..not a string like it says below */
          //Cookies.set("session", data["user"] + "-" + data["id"]);
       }
       if ("instance" in params && window.location.pathname.indexOf("plan_table_with_buttons") >= 0) {
          const plans = await WixGetPlans();
          console.log(JSON.stringify(plans));
          var planIdArray = [];
          for (let x=0;x<plans["_items"].length;x++) {
              planIdArray.push({"_id": plans["_items"][x]["_id"], "name": plans["_items"][x]["name"].toLowerCase()});
          }
console.log(JSON.stringify(planIdArray));
          setPlanIds(planIdArray);
       }
    }
    loadPage();
  }, [window.location, window.location.search]);

  useEffect(() => {
    if (globalData.debug) console.log("IN HERE App useEffect");
    async function loadPage() {
      //await loadUser(); KAD Aug 1, 2024...perhaps put back
      //await loadReference(); don't need now Aug 13, 2024
      if (false && user && user.length > 0)
         await loadUserDeep(user);
    }
    loadPage();
  }, [user]);

  const style1 = {
    width: "100%",
    height: "100%",
  };
  const style2 = {
    width: "100%",
    //height: "80vh",
    height: "100%",
  };

  function isAdmin() {
    if (userDeep && userDeep.roles && userDeep.roles.toLowerCase().indexOf("admin") >= 0) return true;
    return false;
  }
  /* TODO do I need this users variable below in the Header */
  /* it the callback below which ultimately sets the User for the session */
  if (isMobile) {
     return ( 
         <div>This app performs better on a computer browser</div>
     ) 
  } else
  return (
    <>
      {data["showAdminToolChat"] === true && 
      <>
      <button style={{position: "fixed", top: "60px", right: "20px", zIndex: "999"}} onClick={toggleChatWindow}><img src="https://agent-prod.genaisolutions.ai/chatbot.png" height="40px" width="40px"></img></button>
      {chatWindowState && 
      <>
        <ChatBot 
          iframeSrc={data && data["adminToolChatBot"]} 
        />
      </>
      }
      </>
      }
      <Router>
      {pathName.indexOf('plan_table') < 0 && 
      <Header users={users} userDeep={userDeep} user={user} reference={reference} callback={registerLoggedInUser} iframeLogin={iframeLogin} />
      }
        <div style={style1}>
          <main>
            <div className="main-area" style={style2}>
              <Switch>
                {isAdmin() &&
                  <Route exact path="/admin">
                    <Admin user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                  </Route>
                }
                <Route exact path="/plan_table">
                  <PlanTable currentPlanName="none" showButtons={false}/>
                </Route>
                <Route exact path="/plan_table_with_buttons">
                  <PlanTable currentPlanName={userDeep && userDeep.planName} showWixButtons={true} planIds={planIds}/>
                </Route>
                <Route exact path="/wix">
                  <Agents user={user} id={id} userDeep={userDeep} />
                </Route>
                <Route exact path="/">
                  <Home   user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/stripe">
                  <Stripe user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/subscribe">
                  <Home user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/home">
                  <Home user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/agents">
                  <Agents user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/user_admin">
                  <UserAdmin user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/help">
                  <Help user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/contact">
                  <Contact user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route path="/login-callback">
                  <WixLoginCallback />
                </Route>
                <Route path="/subscriptions">
                  <Subscriptions />
                </Route>
                <Route path="/stripe">
                  <Stripe />
                </Route>
                <Route path="/user">
                  <UserRoute user={user} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
              </Switch>
            </div>
          </main>
        </div>
      </Router>
    </>
  );
}

export default App;
/* for the future
              <Route
                path="/search/:search"
                render={() => {
                  return (
                    <>
                    <SearchResults user={userDeep}/>
                    </>
                  );
                }}
              />
      <Image source={"https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg"} height="40px" width="40px" style={{position: "absolute", bottom: 0, right: 0}}></Image>
{data["environmentName"] === "Production" &&
                  <Agents user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
}
*/
