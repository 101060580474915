import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Sidebar from "../components/Sidebar";
import FailedMessages from "../components/FailedMessages";
import AdminStatistics from "../components/AdminStatistics";
import Plans from "../components/Plans";
import Users from "../components/Users";
import AdminAgents from "../components/AdminAgents";
import AdminFeedback from "../components/AdminFeedback";
import AdminConfig from "../components/AdminConfig";
import GlobalContext from '../GlobalContext';
import { useMyContext } from '../MyContext';

export default function Admin(props) {
  const [failedMessages, setFailedMessages] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");
  const [screen, setScreen] = useState("Config");
  const myContext = useMyContext();

  async function handleBackupAdminConfig() {
     const response = await fetch("/api/copyObject/adminConfig/adminConfigBackup/" + props.user);
     const data = await response.json();
     if (data.success) {
        alert("Admin Config has been backed up");
     }
     else {
        alert("Admin Config could NOT be backed up");
     }
  }

  async function handleRestoreAdminConfig() {
     const response = await fetch("/api/copyObject/adminConfigBackup/adminConfig/" + props.user);
     const data = await response.json();
     if (data.success) {
        alert("Admin Config has been restored");
     }
     else {
        alert("Admin Config could NOT be restored");
     }
  }

  const getAdminData = async (id) => {
    console.log("IN getAdminData - api/adminData")
    try {
      await fetch("/api/getAdminData")
        .then(response => response.json())
        .then(data => {
          //console.log("data = " + JSON.stringify(data));
          setFailedMessages(data["failed_messages"]);
          setUsers(data["users"]);
          setFeedback(data["feedback"]);
          setId(data["id"]);
        })
    }
    catch (e) {
      console.log(`Failed to getAdminData`);
      console.log(e);
      console.log(e.stack);
    }
  }

  useEffect(() => {
    async function loadAdminData() {
      console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
      const data = await getAdminData();
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      <div style={{display:"flex"}}>
      <div style={{flex:"1"}}>
      <Sidebar callback={setScreen}/>
      </div>
      {props.user && props.user.length > 0 && props.showLoginErrorMessage &&
      <div style={{flex:"4"}}>
        <>
         {screen === 'Failed Messages' && 
              <FailedMessages failedMessages={failedMessages} user={props.user} userDeep={props.userDeep} />
         }
         {screen === 'Statistics' && 
              <AdminStatistics user={props.user} userDeep={props.userDeep} />
         }
         {screen === 'Feedback' && 
              <AdminFeedback feedback={feedback} user={props.user} userDeep={props.userDeep} />
         }
         {screen === 'Users' && 
              <Users user={props.user} userDeep={props.userDeep} />
         }
         {screen === 'AdminAgents' && 
              <AdminAgents user={props.user} userDeep={props.userDeep} />
         }
         {screen === 'Config' && 
              <>
              <Button onClick={handleBackupAdminConfig}>Backup Config</Button>&nbsp; <Button onClick={handleRestoreAdminConfig}>Restore Config</Button>
              <AdminConfig id={id} user={props.user} userDeep={props.userDeep} />
              </>
         }
         {screen === 'Plans' && 
              <Plans user={props.user} userDeep={props.userDeep} />
         }
        </>
        </div>
      }
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
