import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Components
import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import SuperAppObject from "../components//superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

export default function Billing(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [ count, setCount] = useState(0);
  const [ stripeSessionId, setStripeSessionId] = useState(props.userDeep.stripeSessionId);


  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
      <React.StrictMode>
         <Container ref={containerRef} fluid>
            <Row>
               <Col style={superAppStyle.masterPageStyle}>
   {props.user && props.user.length > 0 &&
    <>
    <h3>Billing Information</h3>
    {!stripeSessionId && 
       <>You are not billing anything yet</>
    }
    {stripeSessionId && 
    <>
      <form action="/api/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={stripeSessionId}
        />
        <Button id="checkout-and-portal-button" type="submit">
          View your billing information
        </Button> &nbsp;
        <Button id="checkout-and-portal-button" type="submit">
          Cancel your service
        </Button>
      </form>
    </>
    }
    </>
   }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
            </Col>
         </Row>
      </Container>
      </React.StrictMode>
  );
}
