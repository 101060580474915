import React, { useEffect, useState, useContext } from "react";


//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import UserAdminSidebar from "../components/UserAdminSidebar";
import Billing from "../components/Billing";
import Statistics from "../components/Statistics";
import GlobalContext from '../GlobalContext';

export default function UserAdmin(props) {
  const [screen, setScreen] = useState("Billing");

  useEffect(() => {
    async function loadAdminData() {
      console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      <div style={{display:"flex"}}>
      <div style={{flex:"1"}}>
         <UserAdminSidebar callback={setScreen}/>
      </div>
      {props.user && props.user.length > 0 && props.showLoginErrorMessage &&
      <div style={{flex:"4"}}>
        <>
          {screen === 'Billing' && 
              <Billing user={props.user} userDeep={props.userDeep} />
          }
          {screen === 'Statistics' && 
              <Statistics user={props.user} userDeep={props.userDeep} />
          }
        </>
        </div>
      }
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
          {screen ==='Billing' && 
              <Billing user={props.user} userDeep={props.userDeep} />
          }
*/
