import React, { useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import Feedback from "../components/Feedback";
import GlobalContext from '../GlobalContext';

export default function Contact(props) {
   const containerRef = React.useRef(null);
   const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
      <>
      <React.StrictMode>
         <Container ref={containerRef} fluid>
            <Row>
               <Col style={superAppStyle.masterPageStyle}>
      <React.Fragment>
      {props.user && props.user.length > 0 && 
         <>
         <RowMargin />
         <p> Please contact support at support@genaisolutions.ai for more information</p>
         <Feedback user={props.user} />
         </>
      }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
      </React.Fragment>
            </Col>
         </Row>
      </Container>
      </React.StrictMode>
      </>
  );
}
