import React, { useEffect, useState } from "react";

import { withRouter } from "react-router";
import { useMyContext } from '../MyContext';

import "../pages/Home.css"

//Components

function ShowAgent(props) {
  const [ agentURL, setAgentURL ] = useState("");
  const myContext = useMyContext();

  useEffect(() => {
     console.log("in ShowAgent useEffect");
     async function loadConfig() {
        const tokenResponse = await fetch("/api/getToken");
        const token = await tokenResponse.json(); 
        //console.log("Token = " + JSON.stringify(token)); 
        //console.log("/chat/WWWW/" + props.agent_id + "?accessToken=" + token["accessToken"]);
        setAgentURL(myContext["data"]["agentURL"] + "/chat/WWWW/" + props.agent_id + "/" + token["accessToken"]);
     }
     loadConfig();
  }, [])

  return (
    <div style={{ textAlign: "center" }}>
      <iframe src={agentURL} className="myIframe" height="620" scrolling="no"></iframe>
    </div>
  );
}

export default withRouter(ShowAgent);
